/*=================================
=            Jobs Page            =
=================================*/

.section-jobs {
	padding: 0 15px;
	a {
		color: #000;
		&.thumbnail.job-item:hover, &.thumbnail.job-item:focus {
			text-decoration: none;
		}
		p {
			/*padding: 10px;*/
			color: $mre-grey-light;
			font: 'Raleway';
			font-size: 1.5em;
			font-weight: 500;
			line-height: 1.5em;
		}
	}
	/*.row-jobs {
		padding-top: 50px;
	}*/
}

/*=====  End of Jobs Page  ======*/

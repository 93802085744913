/*==============================
=            Navbar            =
==============================*/

.navbar {
    margin-bottom: 0;
    padding: 0 20px;
}

.navbar-nav > li > .dropdown-menu {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.navbar-right {
    float: right !important;
    margin-right: -15px;
}

.navbar-default  {
	background-color: $mre-green;
	.navbar-nav > li > a {
		color: #fff;
		font-size: 130%;
		&:hover, &:focus {
			color: #fff;
		}
	}
	/* Hamburger Menu text */
	.hamburger-text {
		margin-left: 10px;
		font-size: 130%;
		color: #FFF;
	}
	.navbar-nav > .open > a:focus, .navbar-nav > .open > a:hover, .navbar-nav > .open > a {
		color: #fff;
	}
	.navbar-toggle .icon-bar {
	    background-color: #fff;
	    height: 3px;
	}
}

.navbar-content {
	background-color: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
	.navbar-nav > li > a {
		color: $mre-grey;
		&:hover, &:focus {
			color: $mre-grey;
		}
	}
	/* Hamburger Menu text */
	.hamburger-text {
		color: $mre-grey;
	}
	.navbar-nav > .open > a:focus, .navbar-nav > .open > a:hover, .navbar-nav > .open > a {
		color: $mre-grey;
	}
	.navbar-toggle .icon-bar {
	    background-color: $mre-grey;
	}
}

.navbar-toggle {
	margin-top: 15px;
}

.navbar-cta {
	/* TODO: Check if cleaner way */
	line-height: 68px;

	margin-right: 20px;
}

@media (max-width: $screen-sm-min) {
	.navbar-cta {
		/* TODO: Check if cleaner way */
		line-height: 37px;
    
    	font-size: 1.1em;
	}
}

@media (max-width: $screen-sm-min) {
	.nav > li {
	    float: left;
	}
	.navmenu > .nav > li {
		float: none;
	}
	.navbar-nav .open .dropdown-menu {
	    position: absolute;
	    float: left;
	    width: auto;
	    margin-top: 0;
	    background-color: #fff;
	    border: 1px solid rgba(0, 0, 0, 0.15);
	    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	}
}
/*=====  End of Navbar  ======*/

/*============================
=            Menu            =
============================*/

.navmenu-brand {
	img.logo {
		width: 100%;
	}
}

.navmenu-default .navmenu-nav>li>a, .navbar-default .navbar-offcanvas .navmenu-nav>li>a {
	font-size: 130%;
    color: $mre-grey;
    @include transition(color .3s ease);
}

.navmenu-default .navmenu-nav>li>a:hover, .navbar-default .navbar-offcanvas .navmenu-nav>li>a:hover {
    color: $mre-green;
}

.navmenu-default .navmenu-nav>.active>a, .navbar-default .navbar-offcanvas .navmenu-nav>.active>a, .navmenu-default .navmenu-nav>.active>a:hover, .navbar-default .navbar-offcanvas .navmenu-nav>.active>a:hover, .navmenu-default .navmenu-nav>.active>a:focus, .navbar-default .navbar-offcanvas .navmenu-nav>.active>a:focus {
    color: $mre-green;
    background-color: transparent;
}

/*=====  End of Menu  ======*/
/*======================================
=            Moving landing            =
======================================*/

.moving-landing {
	.container {
		display: flex;
		flex-basis: 80%;
		.col {
			flex-basis: 50%;
			align-self: center;
		}
		h1 {
			font-size: 3.3em;
		}
	}
}

@media (max-width: 767px) {
	.col {
		flex-basis: 100%;
	}
	.moving-landing {
		.container {
			display: block;
		}
	}
}

/*=====  End of Moving landing  ======*/

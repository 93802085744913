/*============================================
=            Register Energy Page            =
============================================*/

.section-energy {
	color: #fff;
	font-size: 1.5em;
	a.link-white {
		color: #fff;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	h1 {
		margin: 0 0 40px 0;
	}
	.wrapper {
		border-radius: 4px;
		padding: 50px;
		background-color: $mre-green;
	}
	.left {
		text-align: left;
	}

}

/*=====  End of Register Energy Page  ======*/
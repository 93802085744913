/*==================================
=            Press Page            =
==================================*/

.section-press {
	padding: 0 15px;
	a {
		img {
			/*margin: 0 auto 30px auto;*/
			background-color: #000;
			opacity: 0.5;
			transition: all 1s ease;
		}
		&:hover {
			img {
				opacity: 1;
			}
		}
	}
	
}

/*=====  End of Press Page  ======*/

/*===================================
=            AVP landing            =
===================================*/

.avp-landing {
	h1.small {
		font-size: 4em;
		padding: 10px 30px 0 30px;
	}
	p {
		font-size: 2em;
		&.star {
			margin-top: 10px;
		}
	}
	img {
		max-width: 30%;
	}
}

@media (min-width: 1200px) {
	.avp-landing {
		h1.small {
			padding: 10px 100px 0 100px;
		}
		.container {
		    flex-basis: 100%;
		}
	}
}

@media (max-width: 570px) {
	.avp-landing {
		h1.small {
			font-size: 3em;
		}
		img {
			max-width: 60%;
		}
		p.star {
			font-size: 1em;
		}
	}
}

/*=====  End of AVP landing  ======*/

/*==============================================
=            Register Complete Page            =
==============================================*/

.section-complete {

	/*height: 400px;*/

	.image-hero-top {
	    z-index: -1;
	    width: 100%;
	    position: absolute;
	    top: 100px;
	    left: 0;
	}

}

/*=====  End of Register Complete Page  ======*/

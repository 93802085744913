/*=====================================
=            Group landing            =
=====================================*/

.group-landing {
	img.img-responsive {
		margin: 0 auto;
	}
	h1.small {
		font-size: 3em;
	}
	.front {
		/*z-index: 10;*/
	}
	.image-hero-bottom {
	    z-index: -1;
	    width: 100%;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	}
}

/*=====  End of Group landing  ======*/

/*==========================================
=            Energy Market Page            =
==========================================*/

.section-market {
	padding: 0 15px;
	h3 {
		color: $mre-grey;
	}
	img {
		margin: 0 auto;
	}
	p {
		@extend .mre-grey-light;
		font: 'Raleway';
		font-size: 1.3em;
		font-weight: 500;
		line-height: 1.5em;
		strong {
			color: $mre-grey;
			font-weight: bold;
		}
	}
}

/*=====  End of Energy Market Page  ======*/

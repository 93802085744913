
@import "resources/assets/sass/variables.scss";

// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "resources/assets/sass/bootstrap";

@import "bootstrap-datepicker3.css";

@import "ekko-lightbox.css";

@import "font-awesome.css";

@import "navbar-offcanvas.css";

@import "resources/assets/sass/common.scss";

@import "resources/assets/sass/menus.scss";

@import "resources/assets/sass/footer.scss";

/**
 *
 * Pages Styles
 *
 */

@import "resources/assets/sass/pages/homepage.scss";

@import "resources/assets/sass/pages/about.scss";

@import "resources/assets/sass/pages/simulation.scss";

@import "resources/assets/sass/pages/simulationResult.scss";

@import "resources/assets/sass/pages/team.scss";

@import "resources/assets/sass/pages/jobs.scss";

@import "resources/assets/sass/pages/press.scss";

@import "resources/assets/sass/pages/contact.scss";

@import "resources/assets/sass/register.scss";

@import "resources/assets/sass/registerComplete.scss";

@import "resources/assets/sass/registerEnergy.scss";

@import "resources/assets/sass/pages/providerChangeSteps.scss";

@import "resources/assets/sass/pages/energyMarket.scss";

@import "resources/assets/sass/login.scss";

/**
 *
 * Landings Styles
 *
 */

@import "resources/assets/sass/landings/easy.scss";

@import "resources/assets/sass/landings/brico.scss";

@import "resources/assets/sass/landings/avp.scss";

@import "resources/assets/sass/landings/railfacilities.scss";

@import "resources/assets/sass/landings/economy.scss";

@import "resources/assets/sass/landings/facealacrise.scss";

@import "resources/assets/sass/landings/group.scss";

@import "resources/assets/sass/landings/rtbf.scss";

@import "resources/assets/sass/landings/moving.scss";

@import "resources/assets/sass/landings/donceel.scss";

@import "resources/assets/sass/landings/ladbrokes.scss";

@import "resources/assets/sass/landings/cerclebrugge.scss";




/*==================================
=            Login Page            =
==================================*/

.section-login {
	.panel {
		h1 {
			margin: 0 0 20px 0;
		}
		color: #fff;
		padding: 50px;
		background-color: $mre-green;
		.btn-link {
			color: #fff;
		}
		a {
			color: #fff;
		}
	}
}

/*=====  End of Login Page  ======*/

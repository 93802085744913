/*====================================
=            Rtbf landing            =
====================================*/

/*=====================================
=            Brico landing            =
=====================================*/

.rtbf-landing {
	h1.small {
		font-size: 4em;
	}
	img.brico {
		width: 30%;
	}
	img.img-responsive {
		margin: 0 auto;
	}
	p.star {
		margin-top: 10px;
	}
}

/*=====  End of Brico landing  ======*/


/*=====  End of Rtbf landing  ======*/

.section-simulation-result {
    h3 {
        color: #fff;
        padding: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 30px;
        border-radius: 3px;
        box-shadow: 2px 2px 4px #b4b4b4;
    }
    .top-logo {
            img {
                 max-height: 50px;
                 
                 width: auto;
                 box-shadow: 0px -2px 8px $mre-grey-light;
                    }
              }
     .logo2{
            img {
                 max-height: 50px;
                 
                 max-width: 100px;
                 box-shadow: 0px -2px 8px $mre-grey-light;
                    }
              }
    .infoBorder{
        border: 1px solid;
        border-color:$mre-green;
        border-radius: 3px;
        box-shadow: 2px 2px 4px #b4b4b4;
        
        padding:1em;
        line-height: 1.5em;
        span{
            color: $mre-grey-light;
            font: 'Raleway';
            font-size: 1.1em;
            font-weight: 500;
            
        }

    }
    .infoTitle{
            color: $mre-grey-light;
            font-family: 'Grand Hotel';
            font-size: 2em;
            font-weight: 500;

     }

    
    .wrapper {
        padding: 25px;
        min-height: 500px;
        border-radius: 3px;
        box-shadow: 2px 2px 4px #b4b4b4;
        .economy {
            border: 5px solid #fff;
            display: inline-block;
            padding: 30px;
            margin: 25px 0;
            color: #fff;
            h4 {
                font-size: 1.5em;
                font-weight: bold;
                display: inline;
            }
            span {
                font-size: 2em;
                &.bold {
                    font-weight: bold;
                }
            }
        }
        img {
            max-width: 60%;
            margin: 0 auto 25px auto;
        }

        .costs {
            padding: 20px;
            color: #fff;
            font-size: 1.3em;
            i.last {
                margin-top: 15px;
            }
            div.total {
                margin-top: 15px;
                font-size: .9em;
            }
        }
        .panel-group .panel + .panel {
            margin-top: 15px;
        }
        .panel {
            background-color: transparent;
            border: 0;
        }
        .panel-default {
            a:hover, a:active, a:visited, a:focus {
                text-decoration: none;
            }
            .panel-heading {
                padding: 20px 15px;
                color: #fff;
                border-radius: 0;
                &.greenOffer {
                    background-color: lighten($mre-green-offer,20%);
                    @include transition(all .3s ease);
                    &:hover {
                        background-color: darken($mre-green-offer,10%);
                    }
                }
                &.bestOffer {
                    background-color: lighten($mre-cheap-offer,5%);
                    @include transition(all .3s ease);
                    &:hover {
                        background-color: darken($mre-cheap-offer,10%);
                    }
                }
                &.standardOffer {
                    background-color: lighten($mre-standard-offer,20%);
                    @include transition(all .3s ease);
                    &:hover {
                        background-color: darken($mre-standard-offer,10%);
                    }
                }
            }
            .panel-body {
                background-color: #fff;
                ul li {
                    list-style-type: none;
                    text-align: left;
                    font-size: 1.3em;
                    color: $mre-grey-light;
                    line-height: 30px;
                    i {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .green {
        h3 {
            background-color: $mre-green-offer;
            margin-top: 40px;
        }
        .wrapper {
            background-color: $mre-green-offer;
        }
        .accordion-section {
            border-bottom: 1px solid $mre-green-offer;
            .accordion-section-title {
                color: $mre-green-offer;
            }
            i {
                color: $mre-green-offer;
            }
        }
        .accordion-section.last {
            border-bottom: none;
        }
        .accordion-subtotal {
            background-color: $mre-green-offer;
        }
        .accordion-total {
            background-color: $mre-orange-light;
            margin: 0 0 20px;
            color: #fff;
            display: inline-block;
            width: 80%;
            padding: 12px 20px;
            span {
                font-size: 1.1em;
            }
        }
    }
    .price {
        h3 {
            background-color: $mre-green;
        }
        .wrapper {
            background-color: $mre-green;
        }
        .accordion-section {
            border-bottom: 1px solid $mre-green;
            .accordion-section-title {
                color: $mre-green;
            }
            i {
                color: $mre-green;
            }
        }
        .accordion-section.last {
            border-bottom: none;
        }
        .accordion-subtotal {
            background-color: $mre-green;
        }
        .accordion-total {
            background-color: $mre-orange-light;
            margin: 0 0 20px;
            color: #fff;
            display: inline-block;
            width: 80%;
            padding: 12px 20px;
            span {
                font-size: 1.1em;
            }
        }
    }
    .standard {
        h3 {
            background-color: $mre-standard-offer;
            margin-top: 55px;
        }
        .wrapper {
            background-color: $mre-standard-offer;
        }
        .accordion-section {
            border-bottom: 1px solid $mre-standard-offer;
            .accordion-section-title {
                color: $mre-standard-offer;
            }
            i {
                color: $mre-standard-offer;
            }
        }
        .accordion-section.last {
            border-bottom: none;
        }
        .accordion-subtotal {
            background-color: $mre-standard-offer;
        }
        .accordion-total {
            background-color: $mre-orange-light;
            margin: 0 0 20px;
            color: #fff;
            display: inline-block;
            width: 80%;
            padding: 12px 20px;
            span {
                font-size: 1.1em;
            }
        }
    }
    .flexy-table {
        color: #fff;
        .table-responsive {
            margin: 15px 0;
            background-color: #b4b4b4;
            padding: 10px 25px;
            box-shadow: 3px 3px 10px #b4b4b4;
            .table {
                tr {
                    th, td {
                        border-top-width: 0;
                        font-size: 1.2em;
                        text-align: center;
                        vertical-align: middle;
                        .flexy-btn-default-smaller {
                            font-size: 1em;
                            color: #fff;
                        }
                    }
                    .highlighted-item {
                        font-size: 2.2em;
                    }
                }
            }
        }
    }
    .accordion-section {
        padding: 20px;
        .accordion-section-title {
            font-size: 1.1em;
        }
        .accordion-item {
            display: flex;
            align-items: center;
            padding: 8px 0;
            i {
                padding-right: 10px;
                &:before {
                    font-size: 1.5em;
                }
            }
        }
        .space-between {
            justify-content: space-between;
        }
        .accordion-input {
            input {
                max-width: 70px;
                margin: 0;
            }
        }
        .accordion-subtotal {
            margin: 20px 0;
            color: #fff;
            display: inline-block;
            width: 65%;
            padding: 12px 20px;
            span {
                font-size: 1.1em;
            }
        }
    }
.textResult {
    
    color: $mre-grey-light;
    font: 'Raleway';
    font-size: 1.1em;
    font-weight: 500;
    line-height: 1em;
    span {
        font-weight: bold;
        color: $mre-green;
    }
    strong {
        font-weight: bold;
    }
}
}
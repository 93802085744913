/*==================================================
=            Provider Change Steps Page            =
==================================================*/

.section-provider-steps {
	img {
		margin: 0 auto;
	}
	h4 {
		font-weight: bold;
	}
	p {
		@extend .mre-grey-light;
		font: 'Raleway';
		font-size: 1.3em;
		font-weight: 500;
		line-height: 1.5em;
		strong {
			color: $mre-grey;
			font-weight: bold;
		}
	}
}

/*=====  End of Provider Change Steps Page  ======*/

/*=============================
=            Fonts            =
=============================*/

/* TODO: Update to have them locally instead for faster loading (homepage) */
@import url(https://fonts.googleapis.com/css?family=Grand+Hotel|Slabo+27px|Open+Sans|Raleway:500,700,900|Roboto:300,400,700|Material+Icons);

.raleway-heavy {
	font-family: 'Raleway';
    font-weight: 900;
}
.raleway-medium {
			font-family: 'Raleway';
			font-weight: 500;
		}

.grand-hotel {
    font-family: 'Grand Hotel';
}

.raleway-bold {
    font-family: 'Raleway';
    font-weight: 700;
}

.smaller-title {
	font-size: 0.8em;
}

h2 {
	font-size: 36px;
}

.mre-green {
	color: $mre-green;
}

.mre-grey-light {
	color: $mre-grey-light;
}

.mre-grey {
	color: $mre-grey;
}

.mre-grey-dark {
	color: $mre-grey-dark;
}

.mre-orange-light {
	color: $mre-orange-light;
}

.mre-orange-dark {
	color: $mre-orange-dark;
}

.page-text {
	padding: 50px;
	color: $mre-grey-light;
	font: 'Raleway';
	font-size: 1.7em;
	font-weight: 500;
	line-height: 1.5em;
	span {
		font-weight: bold;
		color: $mre-green;
	}
	strong {
		font-weight: bold;
	}
}

a:focus {
    outline: 5px auto $mre-green;
    outline-offset: -2px; 
}

section h1 {
	margin: 70px 0;
}

section.hero h1 {
	margin: 0 0 10px 0;
}


.fa-question-circle{
		padding: 5px;
		color: #004d47;
}
		

/*h2, h3, h4 {
	font-weight: bold;
}*/

/*=====  End of Fonts  ======*/

/*==============================
=            Mixins            =
==============================*/

/* Transitions */
@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

/* Animations */
@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

/* Buttons */
@mixin mre-button($color, $background1, $background2) {
	color: $color;
	background-color: $background1;
	border-bottom: thick solid $background2;
	@include transition(all .3s ease);

	&:focus, &.focus {
		color: $color;
		background-color: $background2;
		border-bottom: thick solid $background1;
	}
	&:hover {
		color: $color;
		text-decoration: none;
		background-color: $background2;
		border-bottom: thick solid $background1;
	}
	&:active, &.active, .open > &.dropdown-toggle {
		color: $color;
		background-color: $background2;
		border-bottom: thick solid $background1;

		&:hover, &:focus, &.focus {
			color: $color;
			background-color: $background2;
			border-bottom: thick solid $background1;
		}
	}
	&:active, &.active, .open > &.dropdown-toggle {
		background-image: none;
	}
	&.disabled, &[disabled], fieldset[disabled] & {
		&:hover, &:focus, &.focus {
			background-color: $background1;
		}
	}

	.badge {
		color: $background1;
		background-color: $color;
	}
}

@mixin lg-button($padding-vertical, $padding-horizontal, $font-size, $line-height, $font-weight) {
	padding: $padding-vertical $padding-horizontal;
	font-size: $font-size;
	line-height: $line-height;
	font-weight: $font-weight;
}

/*=====  End of Mixins  ======*/

/*===============================
=            Buttons            =
===============================*/

/* CTA Args: $color, $background1, $background2 */
.btn-mre {
	@include mre-button(#fff, $mre-orange-light, $mre-orange-dark);
}

button.btn-mre {
	border-top: none;
	border-left: none;
	border-right: none;
}

/* CTA size Args: $padding-vertical, $padding-horizontal, $font-size, $line-height, $font-weight*/
.btn-mre-lg {
	@include lg-button(8px, 25px, 2em, 150%, 600);
}

/* Section CTA */
.section-cta {
	text-align: center;
	padding: 40px 0 45px 0;
}

/*=====  End of Buttons  ======*/

/*===================================================================
=            Changes from offcanvas plugin - TODO: CLEAN UP         =
===================================================================*/


html, body {
	height: 100%;
	font: 0.9em 'Raleway';
}

.navbar-toggle {
	float: left;
}

.navmenu {
	z-index: 1;
	background-color: #fff;
}

.canvas {
	position: relative;
	left: 0;
	z-index: 2;
	min-height: 100%;
	padding: 0;
	background: #fff;
}

@media (min-width: 0) {
	.navbar-toggle {
		display: block; /* force showing the toggle */
	}
}

@media (min-width: 992px) {
	body {
		padding: 0;
	}
/*  .navbar {
    right: auto;
    background: none;
    border: none;
  }*/
	.canvas {
		padding: 0;
	}

	

}

/*=====  End of Changes from offcanvas plugin - TODO: CLEAN UP  ======*/

/*===============================
=            General            =
===============================*/

html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}

.margin-top {
	margin-top: 50px;
}

.margin-bottom {
	margin-bottom: 50px;
}

.margin-top-tiny {
	margin-top: 10px;
}

.container {
	padding: 30px 0;
	&.container-messages {
		padding-bottom: 0;
	}
}

/*=====  End of General  ======*/

/*====================================
=            Contact Page            =
====================================*/

.section-contact {
	.container {
		display: flex;
		flex-basis: 100%;
		.col {
			flex-basis: 50%;
			align-self: center;
		}
	}
	.contact-column-left {
		font-size: 2em;
		.row {
			margin-bottom: 20px;
		}
	}
	.contact-form {
		background-color: $mre-green;
		padding: 50px;
		color: #fff;
		border-radius: 5px;
	}
}

@media (max-width: 767px) {
	.col {
		flex-basis: 100%;
	}
	.section-contact {
		.container {
			display: block;
		}
	}
}

/*=====  End of Contact Page  ======*/

/*===========================================
=            Variables Overrides            =
===========================================*/

/* Mr Energie Colors */
$mre-green: #00A99D !default;
$link-color: $mre-green; /* Same color as above!! */
$mre-grey: #656565 !default;
$mre-grey-dark: #1E1E1E !default;
$mre-orange-light: #F7931E !default;
$mre-grey-light: #b4b4b4 !default;
$mre-orange-dark: #ff6700 !default;
$mre-green-offer: #4DB661 !default;
$mre-cheap-offer: $mre-green; /* Same color as $mre-green!! */
$mre-standard-offer: #A5A5A5 !default;

/* Navbar height */
$navbar-height: 70px !default;

/* Navbar toggle */
$navbar-default-toggle-hover-bg: transparent !default;
$navbar-default-toggle-icon-bar-bg: #888 !default;
$navbar-default-toggle-border-color: transparent !default;

/* Navbar Colors */
$navbar-default-color: #fff !default;
$navbar-default-bg: transparent !default;
$navbar-default-border: none !default;

/* Thumbnails */
$thumbnail-padding: 10px !default;

/* Input border glow color */
$input-border-focus: $mre-orange-light;

/*=====  End of Variables Overrides  ======*/
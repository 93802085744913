/*=====================================
=            Register Page            =
=====================================*/

.section-register, .section-billing {
	padding-bottom: 150px;
	h1, h3, h4, h5 {
		color: #fff;
		margin-bottom:40px;
	}
	.nav-tabs > li {
	    width: 50%;
	    text-align: center;
	}
	.tab-content {
		background-color: $mre-green;
		border-radius: 0 0 4px 4px;
	}
	.nav {
	    margin-bottom: -2px;
	    border: none;
	}
	.nav-tabs {
	    border: none;
	}
	.nav-tabs > li > a {
	    margin-right: 0;
	}
	.nav-tabs > li > a:hover {
        border-color: transparent;
        background-color: transparent;
	}
	.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	    color: #fff;
	    background-color: $mre-green;
	    border: none;
	}
	.tab-pane {
		/*background-color: $mre-green;*/
		padding: 50px;
		label {
			color: #fff;
		}
	}
	.wrapper {
		background-color: $mre-green;
		padding: 50px;
		border-radius: 4px;
		label {
			color: #fff;
		}
	}
	.conditionsGenerales {
		text-decoration: underline;
		color: #fff;
		&:hover {		
			color: #fff;
		}
	}
	.form-private {
		padding: 0 15px;
	}
@media (max-width: $screen-sm-min) {
	
	.form-control {
		margin-bottom:10px;
	}
	.form-group {
		.radio-inline.moving{
			display:block;
			margin-left:0;
			margin-top: 10px;
		}
	}

}
}







/*=====  End of Register Page  ======*/

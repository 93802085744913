/*======================================
=            Section Footer            =
======================================*/

.section-footer {

	.contact {
		color: #fff;
		text-align: center;
		background-color: $mre-green;
		padding: 5px 0;
		.row {
			margin-top: 50px;
		}
		a {
			color: #fff;
			font-size: 2em;
			&:hover {
				text-decoration: none;
			}
		}
		i {
			font-size: 3em;
			margin-bottom: 20px;
		}
		.social-icon {
			font-size: 1em;
			margin: 0 20px;
		}

		.call {
			margin-bottom: 15px;
		}

	}
	.footer-menu {
		background-color: $mre-grey-dark;
		padding: 5px 5px 40px 0px;
		a {
			color: #888;
			font-size: 1.1em;
			@include transition(color .3s ease);
			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}
		
			span.text-white {
			color: #fff;
		}
		span.text-grey {
			color: $mre-grey;
		}
		.small {
			color: $mre-grey;
			font-size: 1em;
			margin-left: 100px;
		}
		.footer-column {
			border-top: 3px solid #00A99D;
    		//margin-bottom: 15px;
    		h4 {
    			color: #fff;
    			text-transform: uppercase;
    			margin-bottom: 20px;
    		}
    		ul {
    			padding: 0;
    			list-style-type: none;
    			li {
    			//	margin-bottom: 10px;
    			}
    		}
		}
	}
}

@media (max-width: $screen-sm-min) {
	.section-footer .footer-menu .small {
		margin-left: 10px;
	}
}


/*=====  End of Section Footer  ======*/
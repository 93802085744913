/*===================================
=            AVP landing            =
===================================*/

.railfacilities-landing {
    h1.small {
        font-size: 4em;
        padding: 30px ;


    }
    p {
        font-size: 1.5em;
        &.star {
            margin:  10px;

        }
    }
    img {
        max-width: 20%;

    }
    img.logo_partenaire {
        margin-top: 80px;
        max-width: 40%;
    }
      /*  .container {
            flex-basis: 100%;
      } */
    
}

@media (max-width: 1200px) {
    .railfacilities-landing {
        h1.small {
            font-size: 3em;
            
        }
        
        img {
        max-width: 20%;

    }
    img.logo_partenaire
    {
        margin-top: 40px;
        max-width: 40%;
    }
    
    }
}

@media (max-width: 900px) {
    .railfacilities-landing {
        h1.small {
            font-size: 2.8em;
            
        }
        
        img 
{       max-width: 25%;

    }
    img.logo_partenaire
    {
        margin-top: 50px;
        max-width: 30%;
    }
    
    }
}


@media (max-width: 570px) {
    .railfacilities-landing {
        h1.small {
            font-size: 2em;
        }
        img {
            max-width: 40%;
        }
        p.star {
            font-size: 1em;
        }
        img.logo_partenaire
    {
    
        max-width: 50%;
    }
    .hero-cta a
    {
        font-size: 1.5em;
    }
    }
}

/*=====  End of AVP landing  ======*/

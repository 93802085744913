/*================================
=            Homepage            =
================================*/

/**
 *
 * Section Hero
 *
 */

.hero {
	color: #fff;
	height: 100vh;
	background-color: $mre-green;
	position: relative;
	display: flex;
	top: -70px;
	.image-cloud {
		z-index: 1;
	    width: 100%;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	}
	.container {
		/*flex-basis: 50%;*/
		align-self: center;
		text-align: center;
		z-index: 2;
		padding: 0.5em;
		h1 {
			font-size: 6em;
			margin-top: 0;
		}
		.hero-sub {
			font-size: 2em;
		}
		.hero-cta {
			margin-top: 50px;
		}
	}
/*	.image-plane {
	    position: absolute;
	    @include animation('flying 2.8s infinite');
	}*/
	.image-eolienne {
		position: absolute;
	    bottom: 0;
	    right: 10%;
	}
}

@media (min-width: $screen-lg-min) {
	.hero .container {
		flex-basis: 50%;
	}
}

@media (max-width: $screen-sm-min) {
	.hero .container h1 {
		font-size: 4em;
	}
}

@media (max-width: $screen-xs-min) {
	.hero .container h1 {
		font-size: 3em;
	}
}

/**
 *
 * Section Description
 *
 */

.section-description-home {
	padding-bottom: 150px;
	.description-section-flex-wrapper {
		display: flex;
	    margin-top: 150px;
	    .flex-column-description {
			text-align: left;
			align-self: center;
			flex-basis: 50%;
			img {
				margin: 0 auto;
			}
	    	&.text {
	    		padding: 50px;
	    		color: $mre-grey-light;
	    		font: 'Raleway';
				font-size: 2.4em;
				font-weight: 500;
	    		line-height: 1.5em;
	    		span {
	    			font-weight: bold;
	    			color: $mre-green;
	    		}
	    		strong {
	    			font-weight: bold;
	    		}
	    	}
	    }
	}
}

@media (max-width: $screen-sm-min) {
	.section-description-home .description-section-flex-wrapper .flex-column-description.text {
		padding: 10px;
		font-size: 1.4em;
	}
}

/**
 *
 * Section How
 *
 */

.section-how-home {
	color: #fff;
	padding: 100px 0;
	text-align: center;
	background-color: $mre-green;
	h3.subtitle {
		font-family: 'Grand Hotel';
		/*letter-spacing: 0.03em;*/
		font-size: 2em;
		font-weight: bold;
	}
	.item {
		background-color: $mre-green;
		color: $mre-grey;
		/*height: 350px;*/
		margin-bottom: 15px;
		top: 0;
		position: relative;
		line-height: 1.3em;
		font-size: 1.3em;
		transition: all 0.3s ease-in-out;
    	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		img {
			background-color: $mre-green;
			max-height: 100%;
			margin: 0 auto;
		}
		.text {
			background-color: #fff;
			padding: 15px;
			font-size: 1.1em;
			height: 230px;
		}
	}
	.item:hover {
		top: -15px;
	}
}

/**
 *
 * Section Advantages
 *
 */

.section-advantages-home {
	padding: 100px 0;
	text-align: center;
	color: $mre-grey-light;
	h2 {
		color: $mre-green;
	}
	h3 {
		margin: 30px 0;
		font-weight: bold;
	}
	p {
		font-size: 1.5em;
		line-height: 1.6em;
	}
}

/**
 *
 * Section Press
 *
 */

.section-press-home {
	padding: 50px 0;
	background: url("/images/index/background-presse.png");
	background-repeat: no-repeat;
	background-size: cover;
	.container-press {
		padding: 0 250px;
		.carousel-inner {
			
		}
	}
	.carousel-indicators {
		position: relative;
		bottom: 0;
		margin: 20px 0 0 5px;
		bottom: 0;
	    left: 0;
	    width: 100%;
	    li {
	    	border: medium none;
		    border-radius: 0;
		    float: left;
		    /*height: 54px;*/
		    margin-bottom: 25px;
		    margin-left: 0;
		    margin-right: 5px !important;
		    margin-top: 0;
		    width: 100px;
		    img {
		    	/* TODO: All images same size, li & a same size */
		    	/*border: 2px solid #FFFFFF;*/
			    float: left;
			    /*height: 54px;*/
			    left: 0;
			    /*width: 100px;*/
			    filter: grayscale(100%);
			    @include transition(filter .3s ease);
		    }
		    &.active {
		    	img {
			    	/*border: 2px solid #428BCA;
	    			opacity: 0.7;*/
	    			filter: grayscale(0%);
	    			background-color: transparent;
			    }
		    }
	    }
	}
	.item {
		background-color: #fff;
		height: 300px;
		padding: 50px;
		font-size: 2.5em;
		text-transform: uppercase;
		color: $mre-grey-light;
		span {
			color: $mre-orange-light;
			font-weight: bold;
		}
	}
}

@media (max-width: $screen-md-max) {
	.section-press-home {
		.container-press {
			padding: 0 25px;
			.carousel-inner {
				
			}
		}
	}
}

/*=====  End of Homepage  ======*/
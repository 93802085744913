/*=========================================
=            Ladbrokes landing            =
=========================================*/

.ladbrokes-landing {
	img.img-responsive {
		margin: 0 auto;
	}
	img.small {
		width: 40%;
	}
	h1.small {
		font-size: 2em;
		margin-top: 10px;
	}
	span {
		font-size: 3em;
		margin-right: 20px;
	}
	.hero-sub-small {
		font-size: 1.2em;
	}
	.hero-cta-small {
		margin-top: 10px;
	}
}

/*=====  End of Ladbrokes landing  ======*/

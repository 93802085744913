/*=======================================
=            Simulation Page            =
=======================================*/

/**
 *
 * Section Simulation
 *
 */

.section-simulation {
    h1 {
        margin: 0;
    }
}
.redLigne{
    background-color:red;
    width: 70px;
    height: 6px;
    border: 1px solid red;
    border-radius: 10px 10px 10px 10px;
    margin: 30px 30px 30px 30px;

}
#simulation {
   	min-height: calc(30vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
	.layout-row {
		padding: 0 15px;
		font-size: 1.2em;
		
		> .layout-row {
			padding: 0;
		}
	    .group-input {
            color:$mre-grey;

			md-input-container {
                max-width: 200px;
				margin: 10px 0 18px;
			}

			.md-select-value {
				border-bottom-color: $mre-orange-light;

				.md-select-icon:after {
					color: $mre-orange-light;
				}

				&:first-child {
					color: $mre-orange-light;
				}
			}

			
	    }
    }

    .flexy-btn-default {
    	margin-top: 30px;
    }

    .transition {

        transition: all ease-in 500ms;
    }
    .transition.ng-hide {
        opacity: 0;
    }
 

    
    /* .input-postal-code {
        min-width: 44px;
    }
    .input-status {
        min-width: 200px;
    }
    .input-type {
        min-width: 185px;
    }
    .input-contract {
    	    min-width: 115px;
    	} 
    	.input-conso {
    width: 120px;
}*/
    

    @media screen and (max-width: 767px) {
    	.layout-row {
    		display: block;
    		margin: 0 auto;
    	}
    	.group-input {
    		display: block;
    	}
    }
  
}
 .msg{
   position:relative;
   margin-top:10vh;
   color:$mre-green;
   font-size:2.3em;
   } 
.flexy-input {
    color: $mre-orange-light;
    border: transparent;
    border-bottom: 1px solid $mre-orange-light;
    background-color: transparent;
    max-width: 120px;
    margin: 10px 10px 18px;
}
.flexy-input2 {
    color: $mre-orange-light;
    border: transparent; 
    border-bottom: 1px solid $mre-orange-light;
    background-color: transparent;
    max-width: 200px;  
    margin: 10px 10px 18px;
}
.flexy-input:focus {
    outline: transparent;
}
.flexy-input3 {
    color: $mre-orange-light;
    border: transparent;
    border-bottom: 1px solid $mre-orange-light;
    background-color: transparent;
    max-width: 50px;
    margin: 10px 10px 18px;
}

.flexy-input3:focus {
    outline: transparent;
}
.flexy-input2:focus { 
    outline: transparent;
}

#page {
    display: none;
}

#loading {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    background-image: url("http://i.stack.imgur.com/MnyxU.gif");
    background-repeat: no-repeat;
    background-position: center;
}
    @media screen and (max-width: 1220px) {
        .section {
            padding: 20px 0 0;
        }
    }
    .pilone {
        position: absolute;
        left: 50%;
        top: 200px;
        margin-left: -40px;
    }
    .eclair {
        max-width: 40px;
        position: absolute;
        top: 100px;
        left: 49%;
        animation: fournisseurs 4s infinite linear;
    }
    .Belpower{
        position: absolute;
        top: 60px;
        left: 49%;
    }
    .Electrabel{
        position: absolute;
        top: 127px;
        left: 65%;
    }
    .Lampiris{
        position: absolute;
        top: 220px;
        left: 68%;
    }
    .Luminus{
        position: absolute;
        top: 360px;
        left: 69%;
    }
    .Mega{
        position: absolute;
        top: 450px;
        left: 67%;
    }
    .Octa{
        position: absolute;
        top: 560px;
        left: 57%;}
    .EnergyPeople{
        position: absolute;
        top: 560px;
        left: 45%;}
    .Eni{
        position: absolute;
        top: 540px;
        left: 35%;}
    .Essent{
        position: absolute;
        top: 410px;
        left: 28%;
    }
    .Eneco{
        position: absolute;
        top: 300px;
        left: 28%;}
    .Watz{
        position: absolute;
        top: 220px;
        left: 28%;}
    .Poweo{
        position: absolute;
        top: 90px;
        left: 40%;}
    @keyframes fournisseurs {
        8.33% {
            transform: rotate(30deg);
            top: 127px;
            left: 56%;

        }
        16.66% {
            transform:rotate(60deg);
            top: 220px;
            left: 63%;
        }
        24.99% {
            transform: rotate(90deg);
            top: 320px;
            left: 65%;
        }
        33.32% {
            transform: rotate(120deg);
            top: 420px;
            left: 63%;
        }
        41.65% {
            transform: rotate(150deg);
            top: 480px;
            left: 55%;

        }
        49.98% {
            transform: rotate(180deg);
            top: 500px;
            left: 49%;
        }
        58.31% {
            transform: rotate(210deg);
            top: 473px;
            left: 42%;
        }
        66.64% {
            transform: rotate(240deg);
            top: 370px;
            left: 37%;

        }
        74.97% {
            transform: rotate(270deg);
            top: 320px;
            left: 35%;
        }
        83.3% {
            transform: rotate(300deg);
            top: 220px;
            left: 38%;
        }
        91.63% {
            transform: rotate(330deg);
            top: 140px;
            left: 44%;
        }
        100% {
            transform: rotate(360deg);
            top: 100px;
            left: 49%;
        }

    }
    .logoSimulation{
            img {
                 max-height: 50px;

                 max-width: 100px;

                    }

              }

/*=====  End of Simulation Page  ======*/
/*=================================
=            Team Page            =
=================================*/

.section-team {
	padding: 0 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	    width: 100%;
	}
}

/*=====  End of Team Page  ======*/

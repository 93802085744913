/*==================================
=            About Page            =
==================================*/

/**
 *
 * Section Description
 *
 */

.section-about {
	padding-bottom: 150px;
	.about-section-flex-wrapper {
		display: flex;
	    margin-top: 150px;
	    .flex-column-about {
			text-align: left;
			align-self: center;
			flex-basis: 50%;
			video {
				max-width: 100%;
			}
	    }
	}
}

@media (max-width: $screen-sm-min) {
	.section-about .about-section-flex-wrapper .flex-column-about.text {
		padding: 10px;
		font-size: 1.4em;
	}
	.section-about .about-section-flex-wrapper {
		display: block;
	}
	.section-about .about-section-flex-wrapper .flex-column-about {
		flex-basis: 100%;
	}
}

/*=====  End of About Page  ======*/